import type { User } from "@/server/db/schema";

export const getUserRole = (user: User) => {
  switch (user.role) {
    case UserRole.LOCATION_OWNER:
      return "Location Owner";
    case UserRole.OPERATOR:
      return "Operator";
    case UserRole.AD_AGENCY:
      return "Ad Agency";
    case UserRole.SUPER_ADMIN:
      return "Admin";
    case UserRole.INSTALLER:
      return "Installer";
    default:
      console.error("Unknown user role", user.role);
      return "Unknown";
  }
};

export enum UserRole {
  LOCATION_OWNER = 0,
  OPERATOR = 1,
  AD_AGENCY = 2,
  INSTALLER = 3,
  SUPER_ADMIN = 999,
}

export const UserRoleOptions = [
  {
    label: "Location Owner",
    value: UserRole.LOCATION_OWNER,
  },
  {
    label: "Operator",
    value: UserRole.OPERATOR,
  },
  {
    label: "Ad Agency",
    value: UserRole.AD_AGENCY,
  },
  {
    label: "Installer",
    value: UserRole.INSTALLER,
  },
];
